import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../scss/pages/notfound.scss"

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="404: Not found" />
    <div className="notfoundWrap">
      <div className="notfound">
        <h1>NOT FOUND</h1>
        <p>Bro!! How did you come here??</p>
        <AniLink cover bg="var(--bg-panel)" direction="right" to={`/`}>Go back to where you are supposed to be.</AniLink>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
